import React, { Component } from 'react';
import Strapi from 'strapi-sdk-javascript/build/main';

// Helpers
import { formatBlogPostTimestamp } from '../utils/formatTimestamps';

// HOC
import withLocation from '../hocs/withLocation';

// Components
import Layout from '../components/Layout';
import SEO from '../components/seo';
import BlogPostsLoading from '../components/BlogPostsLoading';
import BlogPost from '../components/BlogPost';

// Styles
import './blog-post.styles.scss';

const strapi = new Strapi('https://transferable-blog.herokuapp.com');

export class BlogPostPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            blogPost: {},
        };
    }

    async componentDidMount() {
        try {
          window.scrollTo(0, 0);
          const { id } = this.props.search;
          const post = await strapi.getEntry('blogposts', id);
    
          post['formattedDate'] = formatBlogPostTimestamp(post.updatedAt);
          post['content'] = post['content'].replace(/\\n/g, `<p style="margin-top: 1rem"></p>`);
    
          this.setState({
            loading: false,
            blogPost: post
          });
        } catch (err) {
          alert(err);
        }
      }

    render() {
        const { loading, blogPost } = this.state;

        return (
            <Layout currentPath="/blog-post">
                <SEO title="Blog" />
                <div className="blog-post-page">
                    <div className="blog-post-page__content-container">
                    {
                        loading ?
                        <BlogPostsLoading /> :
                        <BlogPost post={blogPost} />
                    }
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withLocation(BlogPostPage);