import React from 'react';
import { Location } from '@reach/router';
import queryString from 'query-string';

const withLocation = ChildComponent => props => (
    <Location>
        {({ location }) => (
            <ChildComponent 
                {...props}
                location={location}
                search={location.search ? queryString.parse(location.search) : {}}
            />
        )}
    </Location>
);

export default withLocation;