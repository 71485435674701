import React, { Component } from 'react';
import { css } from '@emotion/core';
import { BeatLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  transition: all .5s ease;
`;


export default class BlogPostsLoading extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  render() {
    return (
      <div className="blog-posts-loading">
        <BeatLoader
          size={16}
          color={'#ff8c00'}
          loading={this.state.loading}
        />
      </div>
    );
  }
}
