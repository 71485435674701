import React from 'react';

import './BlogPost.styles.scss';

const BlogPost = ({ post }) => (
    <div className="blog-post">


      <div className="blog-post-wrapper">
        <div className="blog-post__header">
          <h1 className="blog-post__title">
            { post.title }
          </h1>

          <p className="blog-post__date">
            { post.formattedDate }
          </p>
        </div>

        <img className="blog-post__image" src={post.image.url} />

        <p className="blog-post__content" dangerouslySetInnerHTML={{ __html: post.content }} />
      </div>
    </div>
);

export default BlogPost;